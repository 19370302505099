<template>
  <div class="px-6 pt-4 pb-9">
    <div class="flex justify-end mb-4">
      <display-limit-selector
        v-model="submissionsData.limit"
        class="mr-4"
        :options="limitOptions"
        @click="handlePageUpdate(1)"
      />
      <base-pagination
        :model-value="submissionsData.page"
        :total-pages="submissionsData.pageCount"
        :limit="submissionsData.limit"
        :total-count="submissionsData.totalCount"
        @update:modelValue="handlePageUpdate($event, true)"
      />
    </div>
    <div class="flex justify-between mb-4">
      <div class="flex items-center mr-4">
        <icon-button
          title="Refresh"
          class="mr-3 w-7.5 h-7.5"
          icon="icons/arrowsClockwise.svg"
          active
          active-btn-class="bg-custom-green-1 bg-opacity-20"
          active-icon-class="text-custom-green-1"
          :is-loading="isSubmissionTableLoading"
          @click="handleRefresh()"
        />
        <icon-button
          title="Open Task"
          class="mr-3 w-7.5 h-7.5"
          icon="icons/folderNotchOpen.svg"
          :active="openTaskButtonActive"
          active-btn-class="bg-blue-600 bg-opacity-20"
          active-icon-class="text-blue-600"
          @click="openSubmissions()"
        />
        <icon-button
          title="Claim Task(s)"
          class="mr-3 w-7.5 h-7.5"
          icon="icons/downloadSimple.svg"
          :active="claimTaskButtonActive"
          :is-loading="isSubmissionTableLoading || isTaskLoading"
          active-btn-class="bg-indigo-600 bg-opacity-20"
          active-icon-class="text-indigo-600"
          @click="taskActions('claim')"
        />
        <icon-button
          title="Unclaim Task(s)"
          class="mr-3 w-7.5 h-7.5"
          icon="icons/thumbsDown.svg"
          :active="unclaimTaskButtonActive"
          :is-loading="isSubmissionTableLoading || isTaskLoading"
          active-btn-class="bg-custom-orange-1 bg-opacity-20"
          active-icon-class="text-custom-orange-1"
          @click="taskActions('unclaim')"
        />
        <icon-button
          v-if="!isUserRestricted"
          title="Assign Task(s)"
          class="mr-3 w-7.5 h-7.5"
          icon="icons/share.svg"
          :active="assignButtonActive"
          :is-loading="isSubmissionTableLoading || isTaskLoading"
          active-btn-class="bg-emerald-600 bg-opacity-20"
          active-icon-class="text-emerald-600"
          @click="openAssignTaskModal('assign')"
        />
        <icon-button
          v-if="!isUserRestricted"
          title="Re-assign Task(s)"
          class="mr-3 w-7.5 h-7.5"
          icon="icons/arrowBendDoubleUpRight.svg"
          :active="reAssignButtonActive"
          :is-loading="isSubmissionTableLoading || isTaskLoading"
          active-btn-class="bg-cyan-700 bg-opacity-20"
          active-icon-class="text-cyan-700"
          @click="openAssignTaskModal('reassign')"
        />
        <icon-button
          active
          title="Export to excel"
          icon="icons/fileX.svg"
          class="mr-3 w-7.5 h-7.5"
          active-icon-class="text-black"
          active-btn-class="bg-black bg-opacity-20"
          @click="exportExcel()"
        />
        <div
          v-if="isPrintingReceipts"
          class="w-7.5 h-7.5"
        >
          <base-svg
            class="w-4 h-4 m-auto py-1 text-primary-red inline-block"
            src="icons/circleSpinner.svg"
            tag="span"
          />
        </div>
        <icon-button
          v-else
          title="Print Receipt"
          class="mr-3 w-7.5 h-7.5"
          icon="icons/printer.svg"
          :active="printReceiptsButtonActive"
          active-btn-class="bg-teal-700 bg-opacity-20"
          active-icon-class="text-teal-700"
          @click="printReceipts()"
        />

        <icon-button
          title="Clear All Filters"
          class="mr-8 w-7.5 h-7.5"
          icon="icons/clockCounterClockwise.svg"
          active
          active-btn-class="bg-red-700 bg-opacity-20"
          active-icon-class="text-red-700"
          @click="clearAllFilters()"
        />
        <base-select
          v-model="savedSearch"
          class="text-sm max-w-full w-52 text-left mr-3"
          placeholder="Saved Searches"
          :disabled="submissionFilters.searchScope.length > 0"
          :options="savedSearchOptions.length > 0 ? savedSearchOptions : ['']"
          show-default-option
          @dropdownOpened="fetchSavedSearches()"
        />

        <!-- filter pills starts -->
        <template v-if="submissionFilters.searchScope?.length > 0">
          <icon-button
            class="mr-3 w-7 h-7"
            icon="icons/trash.svg"
            active
            active-btn-class="bg-custom-orange-1"
            active-icon-class="text-white"
            @click="clearSearchScope()"
          />
          <div
            v-for="(searchScope, sIndex) in submissionFilters.searchScope"
            :key="'simple-search-field' + sIndex"
            class="flex items-center py-0.5 px-2 bg-custom-green-1 bg-opacity-25 mr-3 rounded-sm border border-custom-green-1 border-opacity-20 h-7"
          >
            <span class="text-custom-green-3 text-sm mr-1 truncate">
              {{ searchScope }}
            </span>
            <base-svg
              class="h-3.5 w-3.5 text-custom-green-3 inline-block hover:text-primary-red cursor-pointer align-middle"
              src="icons/cross.svg"
              :svg-attributes="{
                class: 'h-full w-full'
              }"
              tag="span"
              @click="deleteSearchScope(sIndex)"
            />
          </div>
        </template>
        <template v-if="savedSearch.length > 0">
          <icon-button
            class="mr-3 w-7 h-7"
            icon="icons/file.svg"
            active
            active-btn-class="bg-blue-700"
            active-icon-class="text-white"
          />
          <div
            class="flex items-center py-0.5 px-2 bg-custom-green-1 bg-opacity-25 mr-3 rounded-sm border border-custom-green-1 border-opacity-20 h-7"
          >
            <span class="text-custom-green-3 text-sm mr-1 truncate">
              {{ savedSearch }}
            </span>
            <base-svg
              class="h-3.5 w-3.5 text-custom-green-3 inline-block hover:text-primary-red cursor-pointer align-middle"
              src="icons/cross.svg"
              :svg-attributes="{
                class: 'h-full w-full'
              }"
              tag="span"
              @click="clearSavedSearch()"
            />
          </div>
        </template>
      <!-- filter pills ends -->
      </div>
    </div>
    <div class="flex mb-4 w-full">
      <base-select
        :model-value="submissionFilters.columnChooser"
        multi-select
        class="my-auto mr-16 text-sm max-w-full w-64 text-left"
        placeholder="Choose columns to display"
        text-value-override="Choose columns to display"
        :options="submissionColumnChooserOptionsArray"
        @update:model-value="filterColumnChooser($event, submissionFilters.columnChooser)"
      />

      <div class="flex border border-custom-gray-3 p-4 w-full">
        <base-select
          v-model="submissionFilters.submissionTypeName"
          class="text-sm max-w-full w-80 text-left mr-3"
          :options="getDistinctArray(submissionTypeOptions)"
          @dropdownOpened="fetchSubmissionTypes()"
        />

        <base-select
          v-show="!isUserRestricted"
          v-model="submissionFilters.currentStep"
          class="text-sm max-w-full w-48 text-left mr-3"
          :options="submissionStepOptions"
          @dropdownOpened="fetchSubmissionSteps()"
        />
        <base-select
          v-show="!isUserRestricted"
          v-model="submissionFilters.status"
          class="text-sm max-w-full w-40 text-left mr-3"
          :disabled="submissionFilters.currentStep === 'Personal Tasks'"
          :options="submissionStatusOptions"
        />

        <base-select
          :model-value="submissionFilters.searchScope"
          :disabled="savedSearch.length > 0"
          multi-select
          class="text-sm max-w-full w-60 text-left mr-3"
          placeholder="Select Columns to Search..."
          :options="submissionScopeOptions"
          show-default-option
          @update:model-value="filterSearchKeyword($event)"
        />
        <inline-button-input
          v-model="submissionFilters.searchKeyword"
          container-class="max-w-full mr-3"
          placeholder="Search..."
          :btn-props="{
            text: 'Search',
            variant: 'btn-primary',
            disabled: savedSearch.length > 0
          }"
          :input-props="{
            containerClass: 'flex items-center w-full'
          }"
          @click="searchByKeyword()"
        />
      </div>
    </div>
    <div
      v-if="isSubmissionTableLoading"
      class="mt-40 text-center"
    >
      <base-svg
        class="h-4 w-4 mr-1 text-primary-red inline-block"
        src="icons/circleSpinner.svg"
        tag="span"
      />
      Loading ...
    </div>
    <template v-else-if="submissionsData.tableData.data.length > 0">
      <brand-assurance-table
        id="InboxTable"
        v-model="submissionsData.tableData"
        root-element-class="mb-20"
        are-columns-resizable
        are-columns-interchangable
        use-custom-sort-logic
        :are-columns-sortable="!isSubmissionTableSorting"
        @column-dragged="savePreferences()"
        @column-resized="colResized()"
        @columnSorted="sortSubmissionTable($event)"
      >
        <!-- inject link in submission ID column -->
        <template
          v-for="(submissionIdCellSlotName, submissionIndex) in submissionIdCellSlotNames"
          :key="submissionIdCellSlotName"
          #[submissionIdCellSlotName]
        >
          <span
            class="underline cursor-pointer"
            @click="openSubmissions({
              submissionId: submissionsData.tableData.data[submissionIndex].submissionId,
              licenseeName: submissionsData.tableData.data[submissionIndex].licenseeName,
              assignUser: submissionsData.tableData.data[submissionIndex].assignUser,
              submissionStatus: submissionsData.tableData.data[submissionIndex].submissionStatus,
              currentStepName: submissionsData.tableData.data[submissionIndex].currentStep,
            })"
          >
            {{ submissionsData.tableData.data[submissionIndex].submissionId }}
          </span>
        </template>
        <!-- inject formatted date in date column -->
        <template
          v-for="(submissionDateCellSlotName, submissionIndex) in submissionDateCellSlotNames"
          :key="submissionDateCellSlotName"
          #[submissionDateCellSlotName]
        >
          {{ submissionsData.tableData.data[submissionIndex].creationDate ? moment(submissionsData.tableData.data[submissionIndex].creationDate).format('MM/DD/YYYY') : '' }}
        </template>
        <!-- inject formatted date in date column -->
        <template
          v-for="(lastUpdatedCellSlotName, submissionIndex) in lastUpdatedCellSlotNames"
          :key="lastUpdatedCellSlotName"
          #[lastUpdatedCellSlotName]
        >
          {{ submissionsData.tableData.data[submissionIndex].lastmodifiedDate ? moment(submissionsData.tableData.data[submissionIndex].lastmodifiedDate).format('MM/DD/YYYY') : '' }}
        </template>
        <!-- inject formatted date in date column -->
        <template
          v-for="(currentStepDateCellSlotName, submissionIndex) in currentStepDateCellSlotNames"
          :key="currentStepDateCellSlotName"
          #[currentStepDateCellSlotName]
        >
          {{ submissionsData.tableData.data[submissionIndex].stepStartDate ? moment(submissionsData.tableData.data[submissionIndex].stepStartDate).format('MM/DD/YYYY') : '' }}
        </template>
        <!-- inject formatted date in date column -->
        <template
          v-for="(assignedDateCellSlotName, submissionIndex) in assignedDateCellSlotNames"
          :key="assignedDateCellSlotName"
          #[assignedDateCellSlotName]
        >
          {{ submissionsData.tableData.data[submissionIndex].deliveryDate ? moment(submissionsData.tableData.data[submissionIndex].deliveryDate).format('MM/DD/YYYY') : '' }}
        </template>

        <!-- inject update curent step name -->
        <template
          v-for="(currentStepCellSlotName, submissionIndex) in currentStepCellSlotNames"
          :key="currentStepCellSlotName"
          #[currentStepCellSlotName]
        >
          {{ submissionsData.tableData.data[submissionIndex].reviewName ? submissionsData.tableData.data[submissionIndex].reviewName: submissionsData.tableData.data[submissionIndex].currentStep }}
        </template>
        <template
          v-for="(stageStartDateCellSlotName, submissionIndex) in stageStartDateCellSlotNames"
          :key="stageStartDateCellSlotName"
          #[stageStartDateCellSlotName]
        >
          {{ submissionsData.tableData.data[submissionIndex].stageStartDate ? moment(submissionsData.tableData.data[submissionIndex].stageStartDate).format('MM/DD/YYYY') : '' }}
        </template>
      </brand-assurance-table>
      <div class="flex justify-end">
        <display-limit-selector
          v-model="submissionsData.limit"
          class="mr-4"
          :options="limitOptions"
          @click="handlePageUpdate(1)"
        />
        <base-pagination
          :model-value="submissionsData.page"
          :total-pages="submissionsData.pageCount"
          :limit="submissionsData.limit"
          :total-count="submissionsData.totalCount"
          @update:modelValue="handlePageUpdate($event, true)"
        />
      </div>
    </template>
    <!-- assign task modal -->
    <brand-assurance-assign-tasks-modal
      v-model="showAssignTaskModal"
      :mode="assignTaskModalMode"
      :selected-submission="selectedSubmissions"
      @assignUser="handleAssigningTask($event)"
      @hide="setAssignTaskModalVisibility(false)"
    />
    <!-- submision form modal -->
    <brand-assurance-submission-form-modal
      v-model="showSubmissionFormModal"
      @hide="setSubmissionFormModalVisibility(false)"
      @show="setSubmissionFormModalVisibility(true)"
      @minimize="handleSubmissionFormModalMinimize()"
    />
  </div>
</template>

<script>
import { defineAsyncComponent, onMounted, reactive, ref, computed, watch } from 'vue';
import { useStore } from 'vuex';
import BaseSvg from '@/components/generic-components/BaseSvg.vue';
import useToastNotifications from '@/hooks/toastNotifications.js';
import useBaTable from '@/hooks/baTable.js';
import useUserAuth from '@/hooks/userAuth.js';
import useSubmissionForm from '@/components/brand-assurance-submission-form/submissionForm.js';
import useSubmissionFormModal from '@/components/brand-assurance-submission-form/submissionFormModal.js';
import { getDistinctArray } from '@/helpers/util.js';
import { 
    LEGAL_REVIEW_STEP, CONTRACT_COORDINATOR, SOCIAL_DIGITAL_VIDEO_REVIEW, SOCIAL_REVIEW_STEP,
    BAC_FINAL_STEP, BAC_REVIEW_STEP, BAC_SUPERVISOR_STEP, CREATIVE_REVIEW_STEP,
    LICENSEE_STEP, SAFETY_STEP, GAMES_REVIEW_STEP, DIGITAL_REVIEW_STEP
} from '@/constants/submissions';
import {
    WARNING, MAX_FIVE_OPTIONS, SEARCH_KEYWORD_REQUIRED, SELECT_ATLEAST_ONE_COLUMN,
    KEWORD_AND_ATLEAST_ONE_COLUMN_REQUIRED, NO_SUBMISSIONS_FOUND, ERROR, TASK_ACTION_FAILED,
    SUCCESS, TASK_ACTION_SUCCESS
} from '@/constants/alerts';

import {
    BA_ADMIN_ROLE, BAC_ADMIN_ROLE, BAC_REVIEWER_ROLE, BAC_SUPERVISOR_ROLE,
    CREATIVE_ROLE, LEGAL_ROLE, LICENSEE_ROLE, SAFETY_ROLE, SOCIAL_REVIEWER_ROLE, BA_RO_ROLE, GAMES_REVIEWER_ROLE, DIGITAL_REVIEWER_ROLE
} from '@/constants/ba-roles.js';
import moment from 'moment';

export default {
    name: 'BAInbox',

    components: {
        IconButton: defineAsyncComponent(() => import('@/components/IconButton.vue')),
        BaseSelect: defineAsyncComponent(() => import('@/components/generic-components/BaseSelect.vue')),
        DisplayLimitSelector: defineAsyncComponent(() => import('@/components/DisplayLimitSelector.vue')),
        BasePagination: defineAsyncComponent(() => import('@/components/generic-components/BasePagination.vue')),
        InlineButtonInput: defineAsyncComponent(() => import('@/components/InlineButtonInput.vue')),
        BrandAssuranceTable: defineAsyncComponent(() => import('@/components/BrandAssuranceTable.vue')),
        BrandAssuranceAssignTasksModal: defineAsyncComponent(() => import('@/components/BrandAssuranceAssignTasksModal.vue')),
        BrandAssuranceSubmissionFormModal: defineAsyncComponent(() => import('@/components/brand-assurance-submission-form/BrandAssuranceSubmissionFormModal.vue')),
        BaseSvg
    },

    setup () {
        const store = useStore();
        const { addRowColorAndAssignedTo, generateTableSlotNamesByColumnKey } = useBaTable();
        const { generateExcel, fetchedUserPreference, submissionFilters, hasSubmissionAccess } = useSubmissionForm();
        const { updateSubmissionsTabList, resetSubmissionFormModal } = useSubmissionFormModal();
        resetSubmissionFormModal();
        const { notificationsStack } = useToastNotifications();
        const userProxy = computed(() => store.getters['auth/getUserProxyInfo']);
        const userId = computed(() => store.getters['auth/getUserId']);

        const isReadOnlyUser = computed(() => store.getters['auth/isReadOnlyUser']);
        const isUserRestricted = computed(() => isReadOnlyUser.value);

        const { userRoles } = useUserAuth();

        const xlsxColumns = ref(null);
        const xlsxData = ref(null);
        const actualListOfCols = computed(() => store.getters['userPrefs/getActualListOfCols']);
        actualListOfCols.value.forEach(obj => {
            if (obj.key.includes('legal') && userRoles.value.length <= 2 && ([BA_RO_ROLE, LICENSEE_ROLE].includes(userRoles.value[0]) || [BA_RO_ROLE, LICENSEE_ROLE].includes(userRoles.value[1]))) {
                obj.label = obj.label.replace('Legal', CONTRACT_COORDINATOR);
            }
        });

        // saved search logic
        const savedSearches = computed(() => store.getters['baSubmissions/getSubmissionSavedSearches'] || []);
        const savedSearchOptions = computed(() => savedSearches.value.map(search => search.searchName));
        // const userInfo = computed(() => store.getters['auth/getUserInfo']);
        const savedSearchesLoading = ref(false);
        const fetchSavedSearches = async () => {
            try {
                if (savedSearchOptions.value.length > 0 || savedSearchesLoading.value) {
                    return;
                }
                savedSearchesLoading.value = true;
                await store.dispatch('baSubmissions/fetchSavedSearch', {
                    params: {}
                });
            } catch (err) {
                console.error(err);
            } finally {
                savedSearchesLoading.value = false;
            }
        };
        const savedSearch = ref('');
        const clearSavedSearch = () => {
            savedSearch.value = '';
        };
        watch(
            () => savedSearch.value,
            () => {
                clearSubmissionFilters();
                fetchSubmissions();
            }
        );

        watch(
            () => submissionFilters.currentStep,
            () => {
                if (submissionFilters.currentStep === 'Personal Tasks') {
                    submissionFilters.status = 'Active';
                }
            }
        );
        watch(
            () => submissionFilters.submissionTypeName,
            () => {
                submissionsData.page = 1;
                fetchSubmissions();
            }
        );
        watch(
            () => submissionFilters.currentStep,
            () => {
                submissionsData.page = 1;
                fetchSubmissions();
            }
        );
        watch(
            () => submissionFilters.status,
            () => {
                submissionsData.page = 1;
                fetchSubmissions();
            }
        );

        const submissionTypeOptions = computed(() => {
            const submissionTypes = store.getters['baSubmissions/getSubmissionTypes'];
            return submissionTypes && submissionTypes.length ? ['All Submissions', ...submissionTypes.map(type => type.name)] : [];
        });
        const submissionTypesLoading = ref(false);
        const fetchSubmissionTypes = async () => {
            try {
                if (submissionTypeOptions.value.length > 0 || submissionTypesLoading.value) {
                    return;
                }
                submissionTypesLoading.value = true;
                await store.dispatch('baSubmissions/fetchSubmissionTypes', {
                    params: {
                        type: 'SUBMISSION'
                    }
                });
            } catch (err) {
                console.error(err);
            } finally {
                submissionTypesLoading.value = false;
            }
        };

        const submissionStepOptions = computed(() => {
            let tmpSteps = store.getters['baSubmissions/getSubmissionSteps'];
            let usrRoles = userProxy?.value ? userProxy?.value?.roles : userRoles.value;
            tmpSteps = getDistinctArray((tmpSteps || []).map(step => step.stepName));
            tmpSteps = tmpSteps.filter(s => {
                // userRoles.value.includes(BAC_ADMIN_ROLE) ||
                if (usrRoles.includes(BA_ADMIN_ROLE)) return true;
                if (s === LICENSEE_STEP && usrRoles.includes(LICENSEE_ROLE)) return true;
                if (s === BAC_REVIEW_STEP && usrRoles.includes(BAC_REVIEWER_ROLE)) return true;
                if (s === LEGAL_REVIEW_STEP && usrRoles.includes(LEGAL_ROLE)) return true;
                if (s === CREATIVE_REVIEW_STEP && usrRoles.includes(CREATIVE_ROLE)) return true;
                if (s === BAC_SUPERVISOR_STEP && usrRoles.includes(BAC_SUPERVISOR_ROLE)) return true;
                if (s === BAC_FINAL_STEP && usrRoles.includes(BAC_ADMIN_ROLE)) return true;
                if (s === SAFETY_STEP && usrRoles.includes(SAFETY_ROLE)) return true;
                if (s === SOCIAL_REVIEW_STEP && usrRoles.includes(SOCIAL_REVIEWER_ROLE)) return true;
                if (s === GAMES_REVIEW_STEP && usrRoles.includes(GAMES_REVIEWER_ROLE)) return true;
                if (s === DIGITAL_REVIEW_STEP  && usrRoles.includes(DIGITAL_REVIEWER_ROLE)) return true;
                return false;
            });

            return tmpSteps && tmpSteps.length ? ['Personal Tasks', ...tmpSteps] : [];
        });
        const submissionStepsLoading = ref(false);
        const fetchSubmissionSteps = async () => {
            try {
                const tmpSteps = store.getters['baSubmissions/getSubmissionSteps'];
                if (tmpSteps?.length > 0 || submissionStepsLoading.value) {
                    return;
                }
                submissionStepsLoading.value = true;
                await store.dispatch('baSubmissions/fetchSubmissionSteps', {});
            } catch (err) {
                console.error(err);
            } finally {
                submissionStepsLoading.value = false;
            }
        };

        const submissionStatusOptions = ref([
            'Active',
            'Assigned',
            'Unassigned'
        ]);

        const filterSearchKeyword = (selectedOption) => {
            if (selectedOption.includes('Global Search') && !submissionFilters.searchScope.includes('Global Search')) {
                submissionFilters.searchScope = ['Global Search'];
            } else if (selectedOption.includes('Global Search') && submissionFilters.searchScope.includes('Global Search')) {
                submissionFilters.searchScope = selectedOption.filter(e => e !== 'Global Search');
            } else if (!selectedOption.includes('Global Search') && !submissionFilters.searchScope.includes('Global Search')) {
                submissionFilters.searchScope = selectedOption;
            }
            if (submissionFilters.searchScope.length > 5) {
                submissionFilters.searchScope = submissionFilters.searchScope.slice(0, 5);
                notificationsStack.value.push({
                    type: WARNING,
                    message: MAX_FIVE_OPTIONS
                });
            }
        };

        const filterColumnChooser = (selectedOption, val) => {
            submissionsData.tableData.columns = actualListOfCols.value.filter(c => selectedOption.includes(c.label));

            let params;
            if (fetchedUserPreference?.value) {
                fetchedUserPreference.value.style.inbox_preference.columns = submissionsData.tableData.columns;
                params = fetchedUserPreference.value;
            } else {
                params = {
                    key: currentUser.value,
                    label: 'Preferences',
                    style: {
                        inbox_preference: {
                            columns: submissionsData.tableData.columns
                        }
                    }
                };
            }
            store.dispatch('users/saveUserPreferences', { params });
            store.dispatch('userPrefs/updateSubmissionColumns', { submissionColumns: [...submissionsData.tableData.columns] });
            handleRefresh();
        };

        // let actualListOfCols = computed(() => store.getters['userPrefs/getActualListOfCols'])
        const submissionColumnChooserOptionsArray = computed(() =>
            actualListOfCols.value.filter(c => !['Submission Id'].includes(c.label)).map(c => c.label)
        );

        onMounted(() => {
            submissionFilters.columnChooser = cachedSubmissionColumns.value.length
                ? cachedSubmissionColumns.value.map(c => c.label) : actualListOfCols.value.map(c => c.label);
        });

        const submissionScopeOptions = computed(() => ['Global Search', ...submissionsData.tableData.columns.map(col => col.label)]);
        const searchScopeKeys = computed(() => {
            if (submissionFilters.searchScope.length > 0) {
                return submissionFilters.searchScope.map(column => {
                    const matchedCol = submissionsData.tableData.columns.find(tableCol => tableCol.label === column);
                    if (matchedCol) {
                        return matchedCol.key;
                    } else {
                        return '';
                    }
                }).filter(el => el);
            }
            return [];
        });
        const clearSearchScope = () => {
            submissionFilters.searchScope = [];
            handleRefresh();
        };
        const deleteSearchScope = (index) => {
            submissionFilters.searchScope.splice(index, 1);
        };
        const searchByKeyword = () => {
            if (submissionFilters.searchScope.length > 0 && !submissionFilters.searchKeyword) {
                notificationsStack.value.push({
                    type: WARNING,
                    message: SEARCH_KEYWORD_REQUIRED
                });
                return;
            } else if (submissionFilters.searchKeyword && !submissionFilters.searchScope.length) {
                notificationsStack.value.push({
                    type: WARNING,
                    message: SELECT_ATLEAST_ONE_COLUMN
                });
                return;
            } else if (!submissionFilters.searchKeyword && !submissionFilters.searchScope.length) {
                notificationsStack.value.push({
                    type: WARNING,
                    message: KEWORD_AND_ATLEAST_ONE_COLUMN_REQUIRED
                });
                return;
            }
            submissionsData.page = 1;
            fetchSubmissions();
        };
        const clearSubmissionFilters = () => {
            submissionFilters.submissionTypeName = 'All Submissions';
            submissionFilters.currentStep = 'Personal Tasks';
            submissionFilters.status = 'Active';
            submissionFilters.searchScope = [];
            submissionFilters.searchKeyword = '';
        };
        const clearAllFilters = () => {
            store.dispatch('baSubmissions/updateSearchedSubmissionId', { searchedSubmissionId: '' });
            clearSubmissionFilters();
            clearSavedSearch();
        };

        // submission table logic
        const cachedSubmissionColumns = computed(() => store.getters['userPrefs/getSubmissionColumns']);
        const fetchedSubmissions = computed(() => store.getters['baSubmissions/getSubmissionsList']);
        const submissionsData = reactive({
            tableData: {
                columns: cachedSubmissionColumns.value.length ? cachedSubmissionColumns.value : actualListOfCols.value,
                data: []
            },
            page: 1,
            limit: fetchedUserPreference?.value?.style?.inbox_preference?.displayCountPreference ? fetchedUserPreference?.value?.style?.inbox_preference?.displayCountPreference : 50,
            totalCount: fetchedSubmissions.value ? fetchedSubmissions.value.totalCount : 0,
            pageCount: fetchedSubmissions.value ? fetchedSubmissions.value.pageCount : 0
        });

        onMounted(() => {
            setUserPreferences();
        });

        const setUserPreferences = async () => {
            try {
                submissionsData.limit = fetchedUserPreference?.value?.style?.inbox_preference?.displayCountPreference ? fetchedUserPreference?.value?.style?.inbox_preference?.displayCountPreference : 50;
                await fetchSubmissions();
            } catch (err) {
                console.error(err);
            }
        };

        const savePreferences = async () => {
            try {
                let params;
                if (fetchedUserPreference?.value) {
                    fetchedUserPreference.value.limit = {};
                    fetchedUserPreference.value.style.inbox_preference = submissionsData.tableData;
                    fetchedUserPreference.value.style.inbox_preference.displayCountPreference = submissionsData.limit;
                    params = fetchedUserPreference.value;
                } else {
                    params = {
                        key: currentUser.value,
                        label: 'Preferences',
                        style: {
                            inbox_preference: {
                                ...submissionsData.tableData,
                                displayCountPreference: submissionsData.limit
                            }
                        }
                    };
                }
                await store.dispatch('users/saveUserPreferences', { params });
            } catch (err) {
                console.error(err);
            }
        };

        const submissionIdCellSlotNames = computed(() => generateTableSlotNamesByColumnKey(submissionsData.tableData, 'submissionId', 1));
        const submissionDateCellSlotNames = computed(() => generateTableSlotNamesByColumnKey(submissionsData.tableData, 'creationDate', 1));
        const lastUpdatedCellSlotNames = computed(() => generateTableSlotNamesByColumnKey(submissionsData.tableData, 'lastmodifiedDate', 1));
        const currentStepDateCellSlotNames = computed(() => generateTableSlotNamesByColumnKey(submissionsData.tableData, 'stepStartDate', 1));
        const assignedDateCellSlotNames = computed(() => generateTableSlotNamesByColumnKey(submissionsData.tableData, 'deliveryDate', 1));
        const stageStartDateCellSlotNames = computed(() => generateTableSlotNamesByColumnKey(submissionsData.tableData, 'stageStartDate', 1));
        const currentStepCellSlotNames = computed(() => generateTableSlotNamesByColumnKey(submissionsData.tableData, 'currentStep', 1));

        const updateSubmissionsData = () => {
            submissionsData.tableData.data = fetchedSubmissions.value && fetchedSubmissions.value.data.length > 0 ? addRowColorAndAssignedTo(fetchedSubmissions.value.data) : [];
            submissionsData.pageCount = fetchedSubmissions.value.pageCount;
            submissionsData.totalCount = fetchedSubmissions.value.totalCount;
            if (selectedSubmissions.value.length > 0) {
                deSelectSubmissionTable();
            };
            if (submissionsData.tableData.data.length === 0) {
                notificationsStack.value.push({
                    type: WARNING,
                    message: NO_SUBMISSIONS_FOUND
                });
            }
        };
        const deSelectSubmissionTable = () => {
            submissionsData.tableData.data.forEach(tableObj => {
                tableObj.selected = false;
            });
        };

        const isSubmissionTableLoading = ref(true);
        const fetchSubmissions = async (shouldEntireTableReload = true) => {
            try {
                if (shouldEntireTableReload) {
                    isSubmissionTableLoading.value = true;
                }
                if (savedSearch.value) {
                    // if saved search is selected, load submissions from advance search service
                    const matchedSavedSearch = savedSearches.value.find(search => search.searchName === savedSearch.value);
                    await store.dispatch('baSubmissions/advanceSearch', {
                        bodyPayload: {
                            advancedSearch: JSON.parse(matchedSavedSearch.searchJson),
                            page: submissionsData.page,
                            limit: fetchedUserPreference?.value?.style?.inbox_preference?.displayCountPreference ? fetchedUserPreference?.value?.style?.inbox_preference?.displayCountPreference : 50
                        }
                    });
                } else {
                    // otherwise, load submisions from search submissions service
                    const bodyPayload = {
                        page: submissionsData.page,
                        limit: fetchedUserPreference?.value?.style?.inbox_preference?.displayCountPreference ? fetchedUserPreference?.value?.style?.inbox_preference?.displayCountPreference : 50,
                        submissionId: null,
                        ...sortingFilters,
                        searchScope: submissionFilters.searchKeyword.length > 0 ? searchScopeKeys.value : [],
                        searchKeyword: submissionFilters.searchKeyword,
                        submissionStatus: 'Inflight',
                        assignmentType: submissionFilters.status === 'Active' ? '' : submissionFilters.status === 'Assigned' ? 'assigned' : 'unassigned'
                    };
                    //    submissionFilters.searchKeyword.split('Contract Coordinator').join('Leagl');
                    //    console.log(`searchKeyword ... ${submissionFilters.searchKeyword.split('Contract Coordinator').join('Leagl')}`);

                    if (submissionFilters.currentStep === 'Personal Tasks') {
                        bodyPayload.assignUser = userProxy?.value ? userProxy?.value?.username : userId.value;
                    } else {
                        bodyPayload.currentStep = submissionFilters.currentStep;
                    } 
                    if (submissionFilters.submissionTypeName !== 'All Submissions') {
                        bodyPayload.submissionTypeName = submissionFilters.submissionTypeName;
                    }

                    if (fetchedUserPreference?.value && fetchedUserPreference.value?.style && fetchedUserPreference.value?.style?.inbox_preference && fetchedUserPreference.value?.style?.inbox_preference?.columns) {
                        submissionsData.tableData.columns = fetchedUserPreference.value?.style?.inbox_preference?.columns;
                    }
                    //   if (['currentStep', 'nextStep'].every(r => bodyPayload.searchScope.includes(r))) {
                    bodyPayload.searchScope.forEach(searchScope => {
                        if (['currentStep', 'nextStep'].includes(searchScope)) {
                            bodyPayload.searchKeyword = bodyPayload.searchKeyword?.trim().toUpperCase()?.replace(/CONTRACT_COORDINATOR|COORDINATOR|CONTRACT/gi, LEGAL_REVIEW_STEP);
                            bodyPayload.searchKeyword = bodyPayload.searchKeyword?.trim().toUpperCase()?.replace(/SOCIAL_DIGITAL_VIDEO_REVIEW|DIGITAL VIDEO REVIEW|DIGITAL VIDEO/gi, SOCIAL_REVIEW_STEP);
                        }
                    });
                    // }
                    await store.dispatch('baSubmissions/searchSubmissions', { bodyPayload });
                }
                submissionsData.tableData.data.forEach(data => {
                    if (data.materialsSupplied === SOCIAL_DIGITAL_VIDEO_REVIEW && data.currentStep === SOCIAL_REVIEW_STEP) {
                        data.reviewName = SOCIAL_DIGITAL_VIDEO_REVIEW;
                    } else if (userRoles.value.length <= 2 && ([BA_RO_ROLE, LICENSEE_ROLE].includes(userRoles.value[0]) || [BA_RO_ROLE, LICENSEE_ROLE].includes(userRoles.value[1]))) {
                        if (data.currentStep === LEGAL_REVIEW_STEP) {
                            data.reviewName = CONTRACT_COORDINATOR;
                        } else if (data.nextStep === LEGAL_REVIEW_STEP) {
                            data.nextStep = CONTRACT_COORDINATOR;
                        }
                    } else {
                        data.reviewName = '';
                    }
                });
            } catch (err) {
                console.error(err);
            } finally {
                isSubmissionTableLoading.value = false;
            }
        };

        const limitOptions = ref([
            50,
            100,
            200
        ]);
        const handlePageUpdate = async (newPage, paginator = false) => {
            submissionsData.page = newPage;
            if (!paginator) await savePreferences();
            await fetchSubmissions();
        };

        // submissions table sort logic
        const isSubmissionTableSorting = ref(false);
        const sortingFilters = reactive({
            sort: ''
        });

        const colResized = () => {
            savePreferences();
        };

        const sortSubmissionTable = async (columnPayload) => {
            try {
                isSubmissionTableSorting.value = true;
                if (columnPayload.sortOrder) {
                    sortingFilters.sort = `${columnPayload.columnKey},${columnPayload.sortOrder}`;
                } else {
                    sortingFilters.sort = '';
                }
                await fetchSubmissions(false);
            } catch (err) {
                console.error(err);
            } finally {
                // update sorting order in UI
                submissionsData.tableData.columns[columnPayload.columnIndex].sorted = columnPayload.sortOrder;
                isSubmissionTableSorting.value = false;
            }
        };

        // submission table action buttons logic
        const currentUser = computed(() => store.getters['auth/getUserId']);
        const handleRefresh = () => {
            fetchSubmissions();
        };

        const selectedSubmissions = computed(() => submissionsData.tableData && submissionsData.tableData.data.length ? submissionsData.tableData.data.filter(tableObj => tableObj.selected) : []);
        const openTaskButtonActive = computed(() => selectedSubmissions.value.length > 0 && selectedSubmissions.value.length <= 50);
        const openSubmissions = (taskListPayload) => {
            let submissionsToBeOpened = null;
            if (taskListPayload) {
                // submissions opened from submissionId link
                submissionsToBeOpened = [taskListPayload];
            } else {
                // submissions opened via 'open task' button by selecting checkboxes
                submissionsToBeOpened = selectedSubmissions.value.map(submission => {
                    return {
                        submissionId: submission.submissionId,
                        licenseeName: submission.licenseeName,
                        assignUser: submission.assignUser,
                        submissionStatus: submission.submissionStatus,
                        currentStepName: submission.currentStepName ? submission.currentStepName : submission.currentStep
                    };
                });
            }
            updateSubmissionsTabList(submissionsToBeOpened);
            setSubmissionFormModalVisibility(true);

            if (selectedSubmissions.value.length > 0) {
                // de-select checkboxes after submissions are opened
                deSelectSubmissionTable();
            }
        };
        const showSubmissionFormModal = ref(false);
        const setSubmissionFormModalVisibility = (visibility) => {
            showSubmissionFormModal.value = visibility;
        };
        const handleSubmissionFormModalMinimize = () => {
            showSubmissionFormModal.value = false;
        };

        const claimTaskButtonActive = computed(() =>
            selectedSubmissions.value.length > 0 &&
            !selectedSubmissions.value.some(s => !hasSubmissionAccess(s.currentStep)) &&
            selectedSubmissions.value.filter(submission =>
                !submission.assignUser &&
                ['Inflight'].includes(submission.submissionStatus) &&
                (!isUserRestricted.value || submission.currentStep === 'Licensee'))
                .length === selectedSubmissions.value.length
        );
        const unclaimTaskButtonActive = computed(() =>
            selectedSubmissions.value.length > 0 &&
            !selectedSubmissions.value.some(s => !hasSubmissionAccess(s.currentStep)) &&
            selectedSubmissions.value.filter(submission =>
                submission.assignUser &&
                ['Inflight'].includes(submission.submissionStatus) &&
                (!isUserRestricted.value || submission.currentStep === 'Licensee'))
                .length === selectedSubmissions.value.length
        );
        const assignButtonActive = computed(() => claimTaskButtonActive.value);
        const reAssignButtonActive = computed(() => unclaimTaskButtonActive.value);

        const isTaskLoading = ref(false);

        const updatedSubmissionsTasks = computed(() => store.getters['baSubmissions/getSubmissionTask']);

        watch(
            () => showSubmissionFormModal.value,
            () => {
                if (!showSubmissionFormModal.value) {
                    handleRefresh();
                }
            }
        );

        watch(
            () => fetchedSubmissions.value,
            () => {
                updateSubmissionsData();
            },
            { deep: true }
        );

        const taskActions = async (action, newAssignee) => {
            try {
                if (isTaskLoading.value) {
                    return;
                }
                isTaskLoading.value = true;

                const submissions = selectedSubmissions.value.map(sub => {
                    return { submissionId: sub.submissionId, taskId: sub.taskId, currentStepName: sub.currentStep };
                });

                const bodyPayload = {
                    submissions,
                    action
                };

                if (action !== 'unclaim') {
                    bodyPayload.assignUser = currentUser.value;
                }

                if (action === 'assign' || action === 'reassign') {
                    bodyPayload.assignUser = newAssignee;
                }

                try {
                    await store.dispatch('baSubmissions/submissionTasks', { bodyPayload });
                } catch (claimErr) {
                    return notificationsStack.value.push({
                        type: ERROR,
                        message: `Cannot ${action} one or more submissions. Please refresh the Inbox and try again.`
                    });
                }

                await store.dispatch('baSubmissions/updateAssigneeInSubmissionsList', { newSubmissions: updatedSubmissionsTasks.value });
                notificationsStack.value.push({
                    type: SUCCESS,
                    message: TASK_ACTION_SUCCESS
                });
            } catch (err) {
                console.error(err);
                notificationsStack.value.push({
                    type: ERROR,
                    message: TASK_ACTION_FAILED
                });
            } finally {
                isTaskLoading.value = false;
            }
        };

        const assignTaskModalMode = ref('');
        const showAssignTaskModal = ref(false);
        const setAssignTaskModalVisibility = (modalVisibility) => {
            showAssignTaskModal.value = modalVisibility;
        };
        const handleAssigningTask = (newAssignee) => {
            taskActions(assignTaskModalMode.value, newAssignee);
        };

        const openAssignTaskModal = (mode) => {
            assignTaskModalMode.value = mode;
            setAssignTaskModalVisibility(true);
        };

        const isPrintingReceipts = ref(false);
        const printReceiptsButtonActive = computed(() => selectedSubmissions.value.length > 0 && !isPrintingReceipts.value);
        const printReceipts = async () => {
            try {
                if (isPrintingReceipts.value) return;
                isPrintingReceipts.value = true;
                const promises = await store.dispatch('baSubmissions/downloadSubmissionReceipt', {
                    submissionIds: selectedSubmissions.value.map(s => s.submissionId)
                });
                const resp = await Promise.all(promises);
                resp.map(r => {
                    if (!r.data || !r.data.data || r.data.message !== 'Receipt downloaded successfully') {
                        notificationsStack.value.push({
                            type: WARNING,
                            message: `Invalid response for submission receipt [ ${r.config.url.split('/')[1]} ]`
                        });
                    } else {
                        const windowOpened = window.open(r.data.data, '_blank');
                        if (!windowOpened) {
                            notificationsStack.value.push({
                                type: ERROR,
                                message: 'A popup blocker may be preventing the application. Please add this site to your exception list in order to upload/ download.'
                            });
                        }
                    }
                });
            } catch (e) {
                console.log(e);
                notificationsStack.value.push({
                    type: ERROR,
                    message: 'Error while downloading receipt'
                });
            } finally {
                isPrintingReceipts.value = false;
            }
        };

        /* excel logic below */
        const exportExcel = async () => {
            xlsxColumns.value = actualListOfCols.value;
            if (selectedSubmissions.value && selectedSubmissions.value.length > 0) {
                xlsxData.value = selectedSubmissions.value;
            } else {
                await fetchDataForExcelExport();
            }

            generateExcel('InboxSubmission_Export-', xlsxColumns, xlsxData);
        };

        const fetchDataForExcelExport = async () => {
            const bodyPayload = {
                page: 1,
                limit: 2000,
                submissionId: null,
                ...sortingFilters,
                searchScope: submissionFilters.searchKeyword.length > 0 ? searchScopeKeys.value : [],
                searchKeyword: submissionFilters.searchKeyword,
                submissionStatus: 'Inflight',
                assignmentType: submissionFilters.status === 'Active' ? '' : submissionFilters.status === 'Assigned' ? 'assigned' : 'unassigned'
            };
            if (submissionFilters.currentStep === 'Personal Tasks') {
                bodyPayload.assignUser = userId.value;
            } else {
                bodyPayload.currentStep = submissionFilters.currentStep;
            }
            if (submissionFilters.submissionTypeName !== 'All Submissions') {
                bodyPayload.submissionTypeName = submissionFilters.submissionTypeName;
            }
            const res = await store.dispatch('baSubmissions/searchExcelExportSubmissions', { bodyPayload });
            if (res.totalCount > 2000) {
                notificationsStack.value.push({
                    type: ERROR,
                    message: 'Limit reached. Maximum of 2000 records can only be exported. Please change  filters and try again.'
                });
                return '';
            } else {
                xlsxData.value = res.data;
            }
        };

        return {
            getDistinctArray,
            // saved search
            savedSearch,
            savedSearchOptions,
            fetchSavedSearches,
            clearSavedSearch,
            // filters
            submissionFilters,
            fetchSubmissionTypes,
            submissionTypeOptions,
            fetchSubmissionSteps,
            submissionStepOptions,
            submissionStatusOptions,
            submissionScopeOptions,
            filterSearchKeyword,
            clearSearchScope,
            deleteSearchScope,
            searchByKeyword,
            clearAllFilters,
            // submissions table
            submissionsData,
            isSubmissionTableLoading,
            limitOptions,
            handlePageUpdate,
            handleRefresh,
            submissionIdCellSlotNames,
            submissionDateCellSlotNames,
            lastUpdatedCellSlotNames,
            currentStepDateCellSlotNames,
            currentStepCellSlotNames,
            assignedDateCellSlotNames,
            stageStartDateCellSlotNames,
            // submissions table sort
            isSubmissionTableSorting,
            sortSubmissionTable,
            // submission table action button
            openTaskButtonActive,
            openSubmissions,
            claimTaskButtonActive,
            isTaskLoading,
            taskActions,
            unclaimTaskButtonActive,
            assignButtonActive,
            showAssignTaskModal,
            assignTaskModalMode,
            setAssignTaskModalVisibility,
            openAssignTaskModal,
            handleAssigningTask,
            reAssignButtonActive,
            printReceiptsButtonActive,
            printReceipts,
            // submission form modal
            showSubmissionFormModal,
            setSubmissionFormModalVisibility,
            handleSubmissionFormModalMinimize,
            savePreferences,
            filterColumnChooser,
            submissionColumnChooserOptionsArray,
            moment,
            isUserRestricted,
            isPrintingReceipts,
            xlsxColumns,
            xlsxData,
            selectedSubmissions,
            exportExcel,
            colResized
        };
    }
};
</script>
